import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  cardTitle,
  coloredShadow,
  container,
  description,
  grayColor,
  hexToRgb,
  mlAuto,
  mrAuto,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const foodVendorsBeveragesStyle = (theme) => ({
  backgroundImage: {
    ...backgroundImage,
    [theme.breakpoints.down("lg")]: {
      height: "1200px",
    },
    [theme.breakpoints.down("md")]: {
      height: "1200px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "2000px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2100px",
    },
  },
  backgroundImageText,
  mrAuto,
  mlAuto,
  title: {
    ...title,
    ...textCenter,
    color: whiteColor,
  },
  container: {
    ...container,
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  aboutButton: {
    marginBottom: "40px",
  },
  description: {
    ...description,
    color: whiteColor,
    marginBottom: "70px",
  },
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "& $title": {
      zIndex: "2",
      position: "relative",
      marginBottom: "30px",
      color: whiteColor,
    },
    "& p": {
      color: whiteColor,
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
    "& $infoArea5": {
      textAlign: "left",
      maxWidth: "310px",
      minHeight: "320px",
      "& div, & h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
        color: whiteColor,
      },
    },
  },
  sectionTestimonials: {
    paddingTop: "50px",
    paddingBottom: "80px",
    textAlign: "center",
    "& $cardTitle": {
      marginTop: "0px",
    },
  },
  // features4: {
  //   padding: "70px 0",
  //   "& $phoneContainer": {
  //     maxWidth: "260px",
  //     margin: "60px auto 0",
  //   },
  //   "& $title,& $container": {
  //     position: "relative",
  //     zIndex: "2",
  //     color: whiteColor,
  //   },
  // },

  features5: {
    ...section,
    backgroundRepeat: "no-repeat",
    position: "relative",
    padding: "80px 0px",
    textAlign: "center",
    "& $title": {
      zIndex: "2",
      position: "relative",
      marginBottom: "30px",
      color: whiteColor,
    },
    "& p": {
      color: whiteColor,
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ",0.65)",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "0",
      left: "0px",
      top: "0px",
    },
  },
  features: {
    textAlign: "center",
    paddingTop: "30px",
    pddingBottom: "0px",
  },
  featuresShowLeft: {
    marginLeft: "auto",
    zIndex: 3,
    "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: whiteColor,
    },
  },
  featuresShowMiddle: {
    zIndex: 3,
    "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: whiteColor,
    },
  },
  featuresShowRight: {
    marginRight: "auto",
    zIndex: 3,
    "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: whiteColor,
    },
  },
  ourClients: {
    textAlign: "center",
    "& img": {
      width: "100%",
      maxWidth: "140px",
      margin: "0 auto",
      display: "inline-block",
    },
  },
  cardTitle: {
    ...cardTitle,
    marginTop: 0,
  },
  cardDescription: {
    fontSize: "16px",
    lineHeight: "1.6em",
    color: grayColor[0],
  },
  coloredShadow,
  alignLeft: {
    textAlign: "left",
  },
  phoneContainer: {
    "& img": {
      maxHeight: "300px",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
      margin: "0 auto 5rem auto",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      margin: "0 auto",
    },
  },
  infoArea5: {
    textAlign: "left",
    maxWidth: "310px",
    minHeight: "320px",
    "& div, & h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: "#fff !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "310px !important",
      minHeight: "230px !important",
    },
  },
  infoArea6: {
    textAlign: "left",
    maxWidth: "310px",
    minHeight: "230px",
    "& div, & h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: "#fff !important",
    },
  },
})

export default foodVendorsBeveragesStyle
