import {
  faBeer,
  faGlassCitrus,
  faIdCard,
  faMoneyBill,
  faWineGlassAlt,
} from "@fortawesome/pro-regular-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// images
// page images
import foodVendorsBeveragesStyle from "assets/jss/material-kit-pro-react/views/foodVendorsPageSections/foodVendorsBeveragesStyle.jsx"
// nodejs library that concatenates classes
import classNames from "classnames"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// react components for routing our app without refresh
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(foodVendorsBeveragesStyle)

export default function FoodVendorsBeverages() {
  const {
    buschLightCan,
    foodVendorsBeveragesBackground,
  } = useStaticQuery(graphql`
    query foodVendorsBeveragesImages {
      buschLightCan: file(
        relativePath: { eq: "food-vendors/busch-light-can.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      foodVendorsBeveragesBackground: file(
        relativePath: { eq: "backgrounds/beer-cans.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={foodVendorsBeveragesBackground.childImageSharp.gatsbyImageData}
        alt="Register Now Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridItem
            md={8}
            className={classNames(classes.mlAuto, classes.mrAuto)}
          >
            <h2 className={classes.title}>
              Beverage List, Prices &amp; Information
            </h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.featuresShowMiddle}
              >
                <InfoArea
                  className={classes.infoArea6}
                  faIcon={faBeer}
                  title="Busch Light: Official Beer of Chilimania"
                  description="Chilimania is proud to serve Busch Light and other Wisconsin Distributor beverages."
                  iconColor="info"
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.featuresShowLeft}
              >
                <InfoArea
                  className={classes.infoArea5}
                  faIcon={faWineGlassAlt}
                  title="Other Drinks"
                  description="We also feature a variety of craft beer, hard cider, and “malternative” adult beverages. There also is Timber Hill wine and Bloody marys from Frenchy’s Fixins."
                  iconColor="info"
                />
                <InfoArea
                  className={classes.infoArea5}
                  faIcon={faGlassCitrus}
                  title="Non-Alcoholic Drinks"
                  description="Our food vendors also sell a variety of non-alcoholic beverages, including soda, lemonade, bottled water and more."
                  iconColor="danger"
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className={classes.featuresShowMiddle}
              >
                <div className={classes.phoneContainer}>
                  <GatsbyImage
                    image={buschLightCan.childImageSharp.gatsbyImageData}
                    alt="Busch Light Can"
                    className={classes.eventLogo}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.featuresShowRight}
              >
                <InfoArea
                  className={classes.infoArea5}
                  faIcon={faMoneyBill}
                  title="Beer Prices"
                  subTitle={"$4 Per Ticket"}
                  description="One ticket = a 16 oz. can (domestic beer) or 12 oz. can (craft beer, cider, etc.), or an individual serving of wine (red or white)."
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.infoArea5}
                  faIcon={faIdCard}
                  title="Note"
                  subTitle={"No ID, No Sale!"}
                  description="Chilimania is a FAMILY EVENT, we want you to have fun, but obvious public intoxication or lewdness will be frowned upon."
                  iconColor="success"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  )
}
